@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./assets/css/fonts.css";

:root {

  --bg-default-static: url('./assets/new_bg_laptop.jpg');
  --bg-default-static-mobile: url('./assets/new_bg_mobile.png');
  --bg-site-bar: url('./assets/site-bar-title-bg.png');
  --bg-modal-title: url('./assets/modal-title.png');
  --bg-static-image: url('./assets/visby_back.jpg');

  --bg-gradient: linear-gradient(90deg, #6e25cc -54.8%, rgba(74, 51, 214, 0) 106.13%);
  --bg-gradient-error: linear-gradient(90deg, #ad464f -54.8%, rgba(214, 51, 129, 0) 106.13%);
}

.bg-gradient {
  background: var(--bg-gradient);
}

.bg-gradient-error {
  background: var(--bg-gradient-error);
}

#root {
  @apply w-screen h-screen laptop:w-full laptop:h-full font-projectContent;
}

.blur_site_bar {
  background: rgba(0, 21, 36, 0.05);
  box-shadow: 0 4.34891px 54.3614px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(55.03px);
}


.border_custom {

  $depthX: 8px;
  $depthY: 12px;
  $borderSize: 1px;

  position: relative;
  z-index: 10;

  &:after, &:before {
    content: "";
    position: absolute;
  }

  &:after {
    z-index: -2;
    width: calc(100% + #{$depthX});
    height: calc(100% + #{$depthY});
    border: #{$borderSize} solid white;
  }

  &:before {
    background-clip: content-box;
    z-index: -1;
    height: 12px;
    width: calc(100% + #{$depthX} + #{$borderSize} * 2);
    top: 50%;
    transform: translate(0, -50%);
  }
}


.border_custom_cube {

  $depthX: 12px;
  $depthY: 12px;
  $borderSize: 1px;

  position: relative;
  z-index: 10;

  &:after, &:before {
    content: "";
    position: absolute;
  }

  &:after {
    z-index: -2;
    width: calc(100% + #{$depthX});
    height: calc(100% + #{$depthY});
    border: #{$borderSize} solid white;
  }

  &:before {
    background-clip: content-box;
    z-index: -1;
    height: 12px;
    width: calc(100% + #{$depthX} + #{$borderSize} * 2);
    top: 50%;
    transform: translate(0, -50%);
  }
}

.gradient_line {
  position: relative;

  &:after, &:before {
    content: "";
    position: absolute;
  }

  &.green {
    &:after {
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: linear-gradient(90deg, #5f9335 4.66%, rgba(217, 217, 217, 0) 100%);
    }
  }

  &.red {
    &:after {
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: linear-gradient(90deg, #933535 4.66%, rgba(217, 217, 217, 0) 100%);
    }
  }

  &:after {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(90deg, #5b3593 4.66%, rgba(217, 217, 217, 0) 100%);
  }
}

.border_remove {
  @apply bg-pColorRed;
  background: linear-gradient(-135deg, transparent 15px, rgba(191, 68, 68, 1) 0);
}


.brd {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff), linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
  background-size: 1px 30px;
  background-repeat: no-repeat, no-repeat;
  background-position: left bottom, right bottom, top left, top right;
}


.bg_custom {
  background: var(--bg-default-static);
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 800px) {
  .bg_custom {
    background: var(--bg-default-static);
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media (max-width: 643px) {
  .bg_custom {
    background: var(--bg-default-static-mobile);
    background-repeat: no-repeat;
    background-size: cover;
  }
}